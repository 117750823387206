<template>
    <div :class="classes" class="px-6 py-4 rounded-md">
        <slot name="default"></slot>
    </div>
</template>

<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'info',
    },
});

const classes = computed(() => {
    return [
        { 'bg-[#00b9ff] text-white': props.type === 'info' },
        { 'bg-[#22c03c] text-white': props.type === 'success' },
        { 'bg-[#ee335e] text-white': props.type === 'danger' },
        { 'bg-[#fbbc0b] text-white': props.type === 'warning' },
    ];
});
</script>
